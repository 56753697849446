import { DestroyRef, Inject, Injectable, computed, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import {
	ActivatedRoute,
	type Data,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	type NavigationExtras,
	NavigationStart,
	type Params,
	type Route,
	Router,
	RouterEvent,
	Routes,
	RoutesRecognized,
} from '@angular/router';

import { Location as AngularLocation } from '@angular/common';
import {
	ActiveFilterService,
	ComponentFilterDefinition,
	InitComponentFilterDefinition,
	RegisteredComponentFilter,
} from '@ft/lib/active-filter-lib';
import { CookieStorageService, POST_LOGIN_URL } from '@ft/lib/cookies-lib';
import { APP_NAME, HOME_URL, ROUTES } from '@ft/lib/core-lib';
import { GlobalStore, LastRouteService } from '@ft/lib/global-lib';
import { APP_NAVBAR_SETTINGS, FT_CreateRouteData, FT_RouteData, NavbarSettings } from '@ft/lib/models-routing';
import { filter, of, switchMap, tap, zip } from 'rxjs';

export type RouteInfo = {
	routeData: Data | null;
	routeParams: Params;
	routeQueryParams: Params;
	baseRoute: string;
	currentUrl: string;
	currentUrlPath: string;
	fragment: string;
	title: string;
	path: string[];
};

@Injectable({
	providedIn: 'root',
})
export class RoutingService {
	destroyRef = inject(DestroyRef);
	router = inject(Router);
	activatedRoute = inject(ActivatedRoute);
	titleService = inject(Title);
	private location = inject(AngularLocation);
	private lastRouteService = inject(LastRouteService);
	private gss = inject(GlobalStore);
	private afs = inject(ActiveFilterService);
	private cookieStorage = inject(CookieStorageService);

	readonly routeInfo = signal<RouteInfo | null>(null);

	readonly baseRoute = computed(() => {
		return this.routeInfo()?.baseRoute ?? '';
	});

	readonly routeQueryParams = computed(() => {
		return this.routeInfo()?.routeQueryParams ?? {};
	});

	readonly routeParams = computed(() => {
		return this.routeInfo()?.routeParams ?? {};
	});

	// navigationRoutes$ = this.router.events.pipe(
	// 	takeUntilDestroyed(),
	// 	filter((evt) => evt instanceof NavigationEnd),
	// 	map((_evt) => this.getNavigationRoutes()),
	// );

	// title
	title = signal<string>(this.appName);

	// route errors
	routeError = signal('');
	routeErrorDesc = signal('');

	constructor(
		@Inject(HOME_URL) public homeUrl: string,
		@Inject(APP_NAME) public appName: string,
		@Inject(ROUTES) private appRoutes: Route[],
		@Inject(APP_NAVBAR_SETTINGS) private defaultNavbarSettings: NavbarSettings,
	) {
		console.debug(`${this.constructor.name} - constructor - appRoutes=`, this.appRoutes);

		/**
		 * Router event
		 */
		this.router.events
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter((evt) => evt instanceof RouterEvent),
			)
			.subscribe((evt) => {
				let eventType = '';
				if (evt instanceof NavigationStart) {
					eventType = 'NavigationStart';
				} else if (evt instanceof NavigationEnd) {
					eventType = 'NavigationEnd';
				} else if (evt instanceof NavigationCancel) {
					eventType = 'NavigationCancel';
				} else if (evt instanceof NavigationError) {
					eventType = 'NavigationError';
				} else if (evt instanceof RoutesRecognized) {
					eventType = 'RoutesRecognized';
				}
				if (!!eventType)
					console.debug(`${this.constructor.name} - RouterEvent - ${evt.id} - ${evt.url} - eventType=${eventType} `);
			});

		/**
		 * Router event
		 */
		this.router.events
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter((evt) => evt instanceof NavigationEnd),
				switchMap((evt) => {
					console.debug(
						`${this.constructor.name} - NavigationEnd  BEG - ${evt.id} - ${evt.type} - ${evt.url} - ${evt.urlAfterRedirects}`,
					);

					const mergedRoute = this.mergeRoute(this.activatedRoute);

					const params$ = mergedRoute.params;
					const mergedRouteData$ = mergedRoute.data;

					const queryParams$ = this.activatedRoute.firstChild
						? this.activatedRoute.firstChild.queryParams
						: this.activatedRoute.queryParams;

					const fragment$ = this.activatedRoute.firstChild
						? this.activatedRoute.firstChild.fragment
						: this.activatedRoute.fragment;

					const title$ = mergedRoute.title;
					console.debug(`${this.constructor.name} - NavigationEnd - finish `);

					return zip([of(evt), params$, queryParams$, fragment$, mergedRouteData$, title$]);
				}),
				tap(([evt, params, queryParams, fragment, mergedRouteData, title]) => {
					this.processRouteEvent(evt, mergedRouteData, params, queryParams, fragment ?? '', title ?? '');
				}),
			)
			.subscribe();

		/**
		 * Process gotoRoute updates
		 */
		effect(() => {
			const gotoRoute = this.gss.goToRoute();
			if (!gotoRoute) return;

			// routing due to gotoRoute being set
			console.debug(`${this.constructor.name} - EFFECT - gss.goToRoute=${this.gss.selectGoToRoute()}`);
			if (gotoRoute === 'home') {
				this.routeToHome();
			} else if (gotoRoute === 'login') {
				this.redirectTo('login');
			} else if (gotoRoute === 'logout') {
				this.redirectTo('logout');
			} else {
				this.redirectTo(gotoRoute);
			}
		});
	}

	/**
	 * Process route event
	 */
	processRouteEvent(
		navigationEnd: NavigationEnd,
		routeData: Data | null,
		routeParams: Params,
		routeQueryParams: Params,
		fragment: string,
		title: string,
	) {
		this.title.set(title || this.appName);

		// get current url
		const currentUrl = navigationEnd.url;

		// get base route
		const { pathString, pathFragments } = this.getBaseRoute();
		const baseRoute = pathString;
		const pathArray = pathFragments;
		console.debug(`${this.constructor.name} - processRouteEvent - currentUrl=${currentUrl}, baseRoute=${baseRoute}`);

		// update route data
		this.processRouteDataChanges(routeData ?? {}, currentUrl);

		// update route info
		const routeInfo: RouteInfo = {
			routeData: routeData,
			routeParams: routeParams,
			routeQueryParams: routeQueryParams,
			baseRoute: baseRoute,
			currentUrl: currentUrl,
			currentUrlPath: baseRoute,
			fragment: fragment,
			title: title || this.appName,
			path: pathArray,
		};

		this.routeInfo.set(routeInfo);
		this.lastRouteService.saveLastRoute(currentUrl);

		/**
		 * handle login error redirect
		 */
		if (baseRoute === '/login' && routeInfo.routeQueryParams['error']) {
			this.routeToHome();
		}
	}

	/**
	 * Process route data changes
	 */
	processRouteDataChanges(data: Data | FT_RouteData<unknown>, currentUrl: string) {
		const routeData = data ? FT_CreateRouteData(data) : FT_CreateRouteData({});

		// default componentFilter
		const componentFilter: ComponentFilterDefinition<unknown> = routeData.componentFilter
			? routeData.componentFilter
			: InitComponentFilterDefinition;
		if (!componentFilter.filterName) componentFilter.filterName = currentUrl ?? '';

		/**
		 * register active filter and activate
		 */
		const showSearchBar =
			routeData.navbarSettings.showSearchBar === undefined ? true : !!routeData.navbarSettings.showSearchBar;

		this.afs.registerComponentFilter(
			{
				filterName: componentFilter.filterName,
				component: componentFilter.component,
				searchString: '',
				initialFilters: componentFilter.initialFilters ?? [],
				fieldFilters: [],
				searchBarFields: componentFilter.searchBarFields ?? [],
				showSearchBar: showSearchBar,
				searchBarInFilterComponent: routeData.searchBarInFilterComponent,
			} as RegisteredComponentFilter<unknown>,
			true,
			false,
		);

		/**
		 * set navbar settings
		 */
		const routeNavbarSettings = routeData.navbarSettings ?? {};
		const navbarSettings = Object.assign(this.defaultNavbarSettings, routeNavbarSettings);
		this.gss.setNavbarSettings(navbarSettings);
	}

	getRouteUrl(): string {
		return this.router.url;
	}

	mergeRoute(route: ActivatedRoute): ActivatedRoute {
		let count = 0;
		let lastRoute = route;
		while (lastRoute.firstChild) {
			count++;
			lastRoute = lastRoute.firstChild;
		}
		return lastRoute;
	}

	private getBaseRoute(): { pathString: string; pathFragments: string[] } {
		let route = this.activatedRoute.root;
		let pathString = '';
		const pathFragments: string[] = [];
		while (route.firstChild) {
			route = route.firstChild;
			const urlPath = route.snapshot.url.length ? route.snapshot.url[0].path : '';
			pathFragments.push(urlPath);
			pathString += `/${urlPath}`;

			// if (route.snapshot.routeConfig) {
			// 	const routePath = route.snapshot.routeConfig.path;
			// 	if (routePath) {
			// 		// Only add static path segments
			// 		const staticSegments = routePath.split('/').filter((segment) => !segment.startsWith(':') && segment !== '**');
			// 		path += `/${staticSegments.join('/')}`;
			// 	}
			// }
		}
		return { pathString: pathString || '/', pathFragments: pathFragments };
	}

	routeToHome(): void {
		const home = this.homeUrl.endsWith('/') ? this.homeUrl : `${this.homeUrl}/`;
		this.updateUrl(this.homeUrl);
		this.router.navigate([home], { queryParamsHandling: 'merge' });
	}

	redirectToHome(): void {
		this.redirectTo(this.homeUrl);
	}

	removeRouteParam(param: string) {
		console.debug(
			`${this.constructor.name} - removeRouteParam - param=${param}, routeInfo.current=${
				this.routeInfo()?.currentUrl
			}, this.router.url=${this.router.url}`,
		);
		const currentUrl = this.router.url.split('/');
		const newUrl = currentUrl.filter((segment) => segment !== param);
		this.router.navigate(newUrl, { skipLocationChange: false });
	}

	// navigateTo(route: string, queryParams?: Params): void {
	// 	this.router.navigate([route, queryParams]);
	// }

	routeByNavigateUrl(url: string): void {
		this.router.navigateByUrl(url, { skipLocationChange: true });
	}

	routeByNavigateUrlWithQueryParams(urlPaths: string[], queryParams: Params): void {
		const url = this.router.createUrlTree(urlPaths, { queryParams }).toString();
		console.debug(`${this.constructor.name} - routeByNavigateUrlWithQueryParams - url=${url}`);
		// this.router.navigateByUrl(url, { skipLocationChange: true });
		this.router.navigateByUrl(url);
	}

	public goToSafeLastRoute() {
		const nextRoute = /login|logout/i.test(this.lastRouteService.lastRoute())
			? this.homeUrl
			: this.lastRouteService.lastRoute();
		this.redirectTo(nextRoute);
	}

	public redirectToPostLoginUrl() {
		const postLoginUrl = this.getPostLoginUrl();
		const lastRoute = /login|logout/i.test(this.lastRouteService.lastRoute())
			? this.homeUrl
			: this.lastRouteService.lastRoute();

		console.debug(
			`${this.constructor.name} - redirectToPostLoginUrl - postLoginUrl=${postLoginUrl}, lastRoute=${lastRoute}`,
		);
		if (postLoginUrl) {
			this.redirectTo(postLoginUrl);
		} else {
			this.redirectTo(lastRoute);
		}
	}

	public redirectTo(path: string, extras?: NavigationExtras) {
		if (!path) return;
		return this.router.navigate([path], extras);
	}

	updateUrl(url: string) {
		this.location.go(url);
	}

	getCurrentUrl(): string {
		return this.location.path();
	}

	savePostLoginUrl(url: string) {
		console.debug(`${this.constructor.name} - savePostLoginUrl - cookie=${POST_LOGIN_URL}, url=${url}`);
		this.cookieStorage.set(POST_LOGIN_URL, url, '1m');
	}

	getPostLoginUrl(): string {
		return this.cookieStorage.get(POST_LOGIN_URL) ?? '';
	}

	isLoginUrl(): boolean {
		return this.getCurrentUrl().toLowerCase().startsWith('/login');
	}

	isLogoutUrl(): boolean {
		return this.getCurrentUrl().toLowerCase().startsWith('/logout');
	}

	isLoginOrLogoutUrl(): boolean {
		console.debug(`${this.constructor.name} - isLoginOrLogoutUrl - currentUrl=${this.getCurrentUrl()}`);
		return this.isLoginUrl() || this.isLogoutUrl();
	}

	isLoginError(): boolean {
		return !!(this.isErrorUrl() && this.isLoginUrl());
	}

	isErrorUrl(): boolean {
		return !!this.routeInfo()?.routeQueryParams['error'];
	}

	updateUrlParam(urlParam: string) {
		const baseRoute = this.routeInfo()?.baseRoute ?? '';
		if (!baseRoute) return;
		const scrubbedValue = typeof urlParam === 'string' ? urlParam.trim() : (urlParam ?? '');
		const newPath = scrubbedValue ? `${baseRoute}/${scrubbedValue}` : baseRoute;
		this.location.replaceState(newPath);
	}

	getNavigationRoutes(): Route[] {
		const navigationRoutes = this.flattenRoute(this.router.config);
		return navigationRoutes;
	}

	flattenRoute(routeConfig: Routes): Route[] {
		const flatRoute = routeConfig
			.flatMap((route) => [route, ...(route.children || [])])
			.filter((route) => route.data?.['showInNavbar']);
		return flatRoute;
	}

	navigate(navigateTo: string[]) {
		this.router.navigate(navigateTo);
	}
}
