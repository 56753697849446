import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
	private storedRoutes = new Map<string, DetachedRouteHandle>();

	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		const shouldDetachResult = route?.data['reuseComponent'] === true;
		return shouldDetachResult;
	}

	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
		const id = this.getRouteId(route);
		if (id) {
			this.storedRoutes.set(id, handle);
		}
	}

	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		const id = this.getRouteId(route);
		return !!id && !!this.storedRoutes.get(id);
	}

	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
		const id = this.getRouteId(route);
		if (!id) {
			return null;
		}
		return this.storedRoutes.get(id) || null;
	}

	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		const shouldReuseResult = future.routeConfig === curr.routeConfig;
		return shouldReuseResult;
	}

	private getRouteId(route: ActivatedRouteSnapshot): string | null {
		const id = route?.data['reuseId'] || null;
		return id;
	}
}
